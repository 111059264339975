import {ListsInterfaces} from "./ListsInterfaces";
import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any): Promise<void>
{
    const response = await FetchWs('list/get-all');
    if(response && response.success && Array.isArray(response.data)){
        vue.table.items = assertList(response.data);
    } else {
        vue.table.items = [];
    }
}

function assertList(objs: ListsInterfaces[]): ListsInterfaces[]
{
    objs.forEach((value:ListsInterfaces,key:number) => {
        objs[key].exportar = value.id
        objs[key].edit_id = value.id
        objs[key].delete_id = value.id
        objs[key].name = value.name
        objs[key].created_date = value.created_date
        objs[key].quantity = value.quantity
    })
    return objs;
}

export async function editList(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'painel-lists-edit', params:{id: id}});
}

export async function deleteList(id: number, vue:any): Promise<void>
{
    await FetchWs('list/delete/' + id, 'GET');
    await refresh(vue);
}

export async function exportarCsv( vue: any, cam_id: string) {
    const response = await FetchWs('list/export/' + cam_id, 'GET');
    if(response && response.success && Array.isArray(response.data)){
        const items: Array<{origem: string, number: string, name: string}> = response.data
        const csvString = [
            [
                "WhatsApp",
                "Nome/URL",
            ],
            ...items.map((item) => [
                item.number,
                item.name
            ])
        ]
            .map(e => e.join(","))
            .join("\n");
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', `Lista-${cam_id}.csv`);
        a.click()
    } else {
        return true;
    }
}